import React, { useRef, useState } from 'react';
import './uploadcard.css';
import CameraCapture from '../../capture_photo/CameraCapture';
import placeholder from '../../../assets/images/27002.jpg';
import { useTranslation } from 'react-i18next';

function UploadCard({ cardTitle, cardDesc, onImageCapture }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const stopCameraRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCapture = (imageDataRaw) => {
    const imageData = imageDataRaw.split(',')[1];
    setCapturedImage(imageData); // Set captured image data
    onImageCapture(imageData); // Pass captured image data to parent component
    closeModal();
  };

  const stopCamera = () => {
    if (stopCameraRef.current) {
      stopCameraRef.current();
    }
  };

  return (
    <>
      <div className='card-container'>
        <div className='left'>
          {capturedImage ? (
            <>
               <img src={`data:image/jpeg;base64,${capturedImage}`} alt='uploaded-img' />
              <p>{t('viewInstructions')}</p>
            </>
          ) : (
            <>
              <img src={placeholder} alt='uploaded-img' />
              <p>{t('viewInstructions')}</p>
            </>
          )}
        </div>
        <div className='right'>
          <div className='card-title'>{cardTitle}</div>
          <div className='card-desc'>{cardDesc}</div>
          <button className='card-btn' onClick={openModal}>
            {t('takePhoto')}
          </button>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <CameraCapture 
              onCapture={handleCapture} 
              isModalOpen={isModalOpen} 
              closeModal={closeModal}
              stopCameraRef={stopCameraRef} 
            />
          </div>
        </div>
      )}
    </>
  );
}

export default UploadCard;
