import React, { useContext, useEffect, useState } from 'react'
import './options.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { SocketContext } from '../../../context/SocketContext';
import { MdAssignment } from "react-icons/md";
import { FaMicrophone, FaMicrophoneSlash, FaPhoneSlash, FaVideo, FaVideoSlash } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import Modal from 'react-modal';
import PhotoModal from './PhotoModal';
import { io } from 'socket.io-client';


// const socket = io('http://localhost:5000');

const Options = ({ children, location }) => {
  const { me, callAccepted, name, stream, setName, callEnded, leaveCall, callUser, userVideo, call  } = useContext(SocketContext);
  const [idToCall, setIdToCall] = useState('');
  const [copied, setCopied] = useState(false);
  const [calling, setCalling] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [capturedPhotoUrl, setCapturedPhotoUrl] = useState('');

  // useEffect(() => {
  //   const handleCallEnded = ({ callId }) => {
  //     console.log(`Call ended for user with ID: ${callId}`);
  //     // Handle call ended event
  //     // Update UI or perform necessary actions
  //   };

  //   socket.on('callEnded', handleCallEnded);

  //   return () => {
  //     // Clean up event listener on component unmount
  //     socket.off('callEnded', handleCallEnded);
  //   };
  // }, []);

  // console.log('this is from the options component:',location)

  // const pancardImage = location.state.imageUrl;

  const handleCapturePhoto = () => {
    if (userVideo.current) {
      const canvas = document.createElement('canvas');
      const video = userVideo.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const photoUrl = canvas.toDataURL('image/jpeg');
      setCapturedPhotoUrl(photoUrl);
      setShowModal(true);
    }
  };

  const handleVerifyPan = () => {
    console.log('Verify PAN clicked');

    handleCapturePhoto();
  };

  const handleVerifyUser = () => {
    console.log('Verify User clicked');

    handleCapturePhoto();
  };

  const handleVerifySignature = () => {
    console.log('Verify Signature clicked');
    handleCapturePhoto();
  };

  const panCardImage = location.state?.imageUrl;



  useEffect(() => {
    if (stream) {
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);

      const analyser = audioContext.createAnalyser();
      source.connect(analyser);

      const checkMicrophoneActivity = () => {
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        analyser.getByteFrequencyData(dataArray);

        const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;


        setIsSpeaking(average > 18);
      };

      const activityInterval = setInterval(() => {
        checkMicrophoneActivity();
      }, 200);

      return () => {
        clearInterval(activityInterval);
        audioContext.close();
      };
    }
  }, [stream]);
  useEffect(() => {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        if (track.kind === 'audio') {
          track.enabled = !audioMuted;
        } else if (track.kind === 'video') {
          track.enabled = !videoMuted;
        }
      });
    }
  }, [stream, audioMuted, videoMuted]);



  const handleCopy = () => {
    setCopied(true);
    console.log('ID copied to clipboard:', me);
  };

  const handleCall = () => {
    setCalling(true);
    callUser(idToCall);
  };

  const toggleAudioMute = () => {
    setAudioMuted(!audioMuted);
  };

  const toggleVideoMute = () => {
    setVideoMuted(!videoMuted);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const isCallDisabled = idToCall.trim() === '';
  const isInitiator = call?.from === me;

  return (
    <>
    {callAccepted && (
    <div className='verify-btns'>
          <button onClick={handleVerifyPan}>Verify PAN</button>
          <button onClick={handleVerifyUser}>Verify User</button>
          <button onClick={handleVerifySignature}>Verify Signature</button>
    </div>
    )}

    <div className='options-container'>
      <div className='options-holder'>
      <div className='media-controls'>
          <button className={`control-btn ${audioMuted ? 'muted' : ''}`} onClick={toggleAudioMute}>
            {audioMuted ? <FaMicrophoneSlash /> : <FaMicrophone className={isSpeaking ? 'active' : ''} />}
          </button>
          <button className={`control-btn ${videoMuted ? 'muted' : ''}`} onClick={toggleVideoMute}>
            {videoMuted ? <FaVideoSlash /> : <FaVideo />}
          </button>
        </div>
          <div className='options-actions-holder'>
            <div className='options-left'>
              <h6>Account Info</h6>
              <div className='form-field'>
                <label htmlFor='name'>Name</label>
                <input type='text' name='name' id='name' onChange={(e)=> setName(e.target.value)}/>
              </div>
              <CopyToClipboard text={me} className='copy-clipboard' onCopy={handleCopy}>
                <button className='copy-btn'>
                  <MdAssignment fontSize="large" />
                  {copied ? 'Copied to Clipboard' : 'Copy Your ID'}
                </button>
              </CopyToClipboard>
            </div>
            {!callAccepted && (
            <div className='options-right'>
              <h6>Make a call</h6>
              <div className='form-field'>
                <label htmlFor='callId'>ID to call</label>
                <input type='text' name='callId' id='callId' onChange={(e) => setIdToCall(e.target.value)} />
              </div>
              <button
                className={`call-action-btns call ${isCallDisabled ? 'disabled' : ''}`}
                onClick={handleCall}
                disabled={isCallDisabled}>
                {calling ? 'Calling...' : <><FaPhoneAlt /> Call</>}
              </button>
            </div>
          )}
          {callAccepted && !callEnded && (
            <div className='options-right'>
              <h6>Call in Progress</h6>
              <button className='call-action-btns hangup' onClick={leaveCall}>
                <FaPhoneSlash />
                Hang Up
              </button>
            </div>
          )}
            </div>

        {children}
      </div>
    </div>
    <PhotoModal isOpen={showModal} onClose={handleCloseModal} photoUrl={capturedPhotoUrl} panCardImage={panCardImage} />

    </>
  )
}

export default Options