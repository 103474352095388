import React from 'react'
import './attemptslimit.css'
import emoji from '../../assets/images/injuried-svgrepo-com.svg'
import { Link } from 'react-router-dom'

function AttemptsLimit() {
  return (
    <div className='accept-container'>
      <div className='accept-holder'>
          <img src={emoji} alt='sad emoji' />
          <h1>No Attempts Left. Please Try again after some time!</h1>
          <p>You have reached the maximum limits for incorrect OTP attempts</p>
          <Link to='/'>

          <button>Return to Home</button>
          </Link>

      </div>
    </div>
  )
}

export default AttemptsLimit