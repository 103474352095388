import React, { useState } from 'react';
import './languageSwitcher.css'; // Import your CSS file
import { useTranslation } from 'react-i18next';
import { GrLanguage } from "react-icons/gr";
import { GoChevronDown, GoChevronUp } from "react-icons/go"; // Use appropriate icons

function LanguageSwitcher({ language }) {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const toggleDropdown = () => {
        setIsOpen(!isOpen); // Toggle state on click
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLanguage(lng);
    };

    return (
        <div className='lan-tablet'>
            <GrLanguage size={22} />
            <div className={`lan-list ${isOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                <div className='lan-selected'>
                    <span className='language'>{language.find(lang => lang.code === selectedLanguage)?.name}</span>
                    {isOpen ? <GoChevronUp /> : <GoChevronDown />} {/* Dynamically change icon */}
                </div>
                <ul>
                    {language.map(lang => (
                        <li key={lang.code} onClick={() => { changeLanguage(lang.code); toggleDropdown(); }}> {/* Toggle dropdown after language change */}
                            {lang.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default LanguageSwitcher;