import React, { useState } from 'react';
import './adminlogin.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../../utility/api';
import { toast } from 'react-toastify';

function AdminLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();


    const payload = {
      userName: username,
      password: password
    };

    try {
   
      const response = await axios.post(API_URL + '/Login/createnewcustomer', payload);

      if (response.status === 200) {
        toast.success("Login Success");
        navigate('/customer-data')
      } else {
        toast.error("Login Failed");
        console.error('Login failed');
    
      }
    } catch (error) {
      toast.error(error.response.data);
      console.error('Error during login:', error);
    }
  };

  return (
    <div className='admin-container'>
      <div className='admin-holder'>
        <h2>{t('loginWithCredentials')}</h2>
        <form onSubmit={handleSubmit}>
          <div className='form-field'>
            <label htmlFor="username">{t('username')}:</label>
            <span className='contact-field'>
              <input
                type='text'
                id='username'
                placeholder={t('username')}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </span>
          </div>
          <div className='form-field'>
            <label htmlFor="password">{t('password')}:</label>
            <span className='contact-field'>
              <input
                type='password'
                id='password'
                placeholder={t('password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </span>
          </div>
          <button type='submit'>{t('loginNow')}</button>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
