import React, { useState } from 'react';
import './docsupload.css';
import UploadCard from './upload_card/UploadCard';
import documentIcon from '../../assets/images/document-svgrepo-com.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import API_URL from '../../utility/api';
import { useData } from '../../context/AdharDataContext';

function DocsUpload() {
  const { userData, setUserData } = useData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [imagesCaptured, setImagesCaptured] = useState({
    panCard: null,
    profilePicture: null,
    signature: null
  });

  console.log("this data is from document upload page:",userData)

  const handleImageCapture = (cardType, capturedImageData) => {
    setImagesCaptured(prevState => ({
      ...prevState,
      [cardType]: capturedImageData
    }));
  };

  const handleProceed = async () => {
    navigate('/consent-check');
    if (!userData || !userData.customerId) {
      console.error('userData is missing or customerId is not set.');
      return;
    }
  
    const { customerId } = userData;
    const payload = {
      customerId,
      panCardImage: imagesCaptured.panCard,
      profileImage: imagesCaptured.profilePicture,
      signatureImage: imagesCaptured.signature
    };
  
    try {
      const response = await axios.post(API_URL + "/Customer/UploadCustomerImages", payload);
  
      console.log('Upload response:', response.data);
     
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  };
  
  // const isProceedEnabled = imagesCaptured.panCard && imagesCaptured.profilePicture && imagesCaptured.signature;
    const isProceedEnabled = true;

  return (
    <div className='docs-container'>
      <div className='docs-holder'>
        <h3>{t('uploadDocuments')}</h3>
        <p>{t('docUploadWillBeUsedForProfileVerification')}</p>
        <UploadCard cardTitle={t('originalPANCard')} cardDesc={t('placeOnWhitePaperInGoodLighting.')} onImageCapture={(imageData) => handleImageCapture('panCard', imageData)} />
        <UploadCard cardTitle={t('profilePictureSelfie')} cardDesc={t('placeYourselfStraightInFrontCameraWithFrontCameraOn.')} onImageCapture={(imageData) => handleImageCapture('profilePicture', imageData)} />
        <UploadCard cardTitle={t('yourSignature')} cardDesc={t('signOnClearWhitePaperWithBluePen.')} onImageCapture={(imageData) => handleImageCapture('signature', imageData)} />

        <img src={documentIcon} alt='documentIcon' />
        <b>{t('noDocuments')}</b>
        <p>{t('dontHaveDocumentsAtMmomentNoProblemAgentVisitKYC')}</p>

        <button className='proceed-btn' onClick={handleProceed} disabled={!isProceedEnabled}>{t('proceed')}</button>
      </div>
    </div>
  );
}

export default DocsUpload;
