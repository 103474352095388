import React, { useEffect, useState } from 'react';
import './slotbooking.css';
import { useData } from '../../context/AdharDataContext';
import axios from 'axios';
import API_URL from '../../utility/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SlotBooking = () => {
  const navigate = useNavigate();
  const { userData } = useData();
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [bookingSlotData, setBookingSlotData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const availableTimeSlots = [
  //   '9:00 AM', '10:00 AM', '11:00 AM',
  //   '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM'
  // ];

  useEffect(() => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 30);

    const formatDateString = (date) => date.toISOString().split('T')[0];
   // fetchBookingSlotData();
    setMinDate(formatDateString(today));
    setMaxDate(formatDateString(futureDate));
  }, []);


  const fetchBookingSlotData = async (seledtedDate) => {
    const selectedDatePaylod = {
      selectedDate: seledtedDate
      }

    try {
      const response = await axios.post(API_URL + '/BookingSlot/GetAvailableBookingSlots', selectedDatePaylod);
      console.log('BookingSlot Fetched successfully:', response.data);
      setBookingSlotData(response.data);
    } catch (error) {
      console.error('Error updating nominee:', error);
    }

    // try {
    //   const response = await axios.get(`${API_URL}/BookingSlot/GetAvailableBookingSlots`);
    //   setBookingSlotData(response.data);
    // } catch (error) {
    //   setError(error);
    // } finally {
    //   setLoading(false);
    // }
}

  const handleDateChange = (e) => {
    console.log("Selected Date ====> ", e.target.value);
    setSelectedDate(e.target.value);
    setSelectedTime('');
    fetchBookingSlotData(e.target.value);
  };


  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };
  console.log(userData)

  const confirmBooking = async () => {
    if (!selectedDate || !selectedTime) {
      alert('Please select both date and time.');
      return;
    }

    try {
      // Split the selectedDate into year, month, and day components
      const [year, month, day] = selectedDate.split('-');

      // Split the selectedTime into time and period components
      const [time, period] = selectedTime.split(' ');

      // Extract hours and minutes from the time component
      let [hours, minutes] = time.split(':');

      // Convert hours to 24-hour format if necessary
      if (period === 'PM' && hours !== '12') {
        hours = (parseInt(hours, 10) + 12).toString(); // Convert PM hours to 24-hour format
      } else if (period === 'AM' && hours === '12') {
        hours = '00'; // Handle midnight (12 AM) as '00' in 24-hour format
      }

      // Construct the requestedKycDate in YYYY-MM-DDTHH:mm:ss format
      const requestedKycDate = `${year}-${month}-${day}T${hours}:${minutes}:00`;
      const requestData = {
        customerId: userData.customerId,
        requestedKycDate: requestedKycDate
      };

      const response = await axios.post(API_URL + '/BookingSlot/UpdateCustomerVKycDate', requestData);
      toast.success("Booking Confirmed for VKYC!");
      navigate('/success')
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  return (
    <div className="slot-booking-container">
      <div className="slot-booking-holder">
        <h2>Date and Time Slot Booking</h2>

      <div className="input-container">
        <label className='label-input' htmlFor="datePicker">Select a Date:</label>
        <input
          type="date"
          id="datePicker"
          value={selectedDate}
          onChange={handleDateChange}
          min={minDate}
          max={maxDate}
        />
      </div>

   
        <div className="time-slots-container">
          <label className='label-input'>Select a Time:</label>
          <div className="time-slots">
            {bookingSlotData.map((time, index) => (
              <button
                key={index}
                className={`time-slot ${selectedTime === time.slotTime ? 'selected' : ''}`}
                onClick={() => handleTimeChange(time.slotTime)}
                disabled={selectedTime === time.available}
              >
                {time.slotTime}
              </button>
            ))}
          </div>
        </div>
        {selectedTime && (
          <div className="confirmation">
            <p className='label-input'>You have selected:</p>
            <p>Date: {selectedDate}</p>
            <p>Time: {selectedTime}</p>
            <button className="confirm-button" onClick={confirmBooking}>
              Confirm Booking
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlotBooking;
