import React, { useState } from 'react'
import './consentcheck.css'
import userChecked from '../../assets/images/user-check-svgrepo-com.svg'
import { useNavigate } from 'react-router-dom';
import { useData } from '../../context/AdharDataContext';
import { useTranslation } from 'react-i18next';

function ConsentCheck() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useData();
    const [checkbox1Checked, setCheckbox1Checked] = useState(false);

    const handleProceed =()=>{

        navigate('/personal-details')
      }
      console.log(userData)
  return (
    <div className='consent-container'>
        <div className='consent-holder'>
            <div className='top'>
            <p>{t('itsGoodToHaveYouBack')}</p>
            <h2>{t('letsupdateYourDetails')}</h2>
            <div className='user-card'>
                <img src={userChecked} alt='card-img'/>
                <span className='user-info'>
                    <b className='user-name'>{userData.firstName}</b>
                </span>
            </div>
            </div>
            <div className='bottom'>
                <div className='checkbox-field'>
                    <input
                        type='checkbox'
                            checked={checkbox1Checked}
                            onChange={(e) => setCheckbox1Checked(e.target.checked)}
                    />
                    <p className='consent-para'>{t('bankRecordsWillGetUpdatedWithPersonalDetails')}</p>
                </div>
                <button onClick={handleProceed} disabled={!checkbox1Checked}>{t('proceed')}</button>
            </div>
        </div>
        
    </div>
  )
}

export default ConsentCheck