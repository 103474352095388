import React, { useContext, useEffect, useRef, useState } from 'react';
import './videocall.css'

import VideoPlayer from './video_player/VideoPlayer';
import Options from './options/Options';
import Notification from './call_notification/Notification';
import { ContextProvider, SocketContext } from '../../context/SocketContext';
import { useLocation, useParams } from 'react-router-dom';

const VideoCall = () => {
  const { customerId } = useParams();
  const location = useLocation();


  
 
  return (
   // <ContextProvider>
    <div className='call-container'>
      {/* <img className='call-background' src='https://w0.peakpx.com/wallpaper/884/95/HD-wallpaper-technology-windows-11-logo-microsoft.jpg' alt='background-img' /> */}
      <div className='call-holder'>
         <h1>Virtual KYC</h1>
      <VideoPlayer/>
      <Options location={location}>
        <Notification/>
      </Options>
      </div>
     
    </div>
   // </ContextProvider>
  );
};

export default VideoCall;
