import React, { useEffect, useState, useRef } from 'react';
import './customerdata.css';
import axios from 'axios';
import API_URL from '../../utility/api';
import { Link } from 'react-router-dom';

function CustomerData() {
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [genderFilter, setGenderFilter] = useState('');
  const [kycFilter, setKycFilter] = useState('');
  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm, genderFilter, kycFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL + '/Customer/getcustomers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const filteredCustomers = customers.filter(customer => {
    const search = searchTerm.toLowerCase();
  
    const fullName = `${customer.firstName} ${customer.lastName}`.toLowerCase();
    const matchesFullName = fullName.includes(search);
    const matchesPan = customer.panNumber.toLowerCase().includes(search);
    const matchesPhoneNumber = customer.phoneNumber.toLowerCase().includes(search);
    const matchesAadharNumber = customer.aadharNumber.toLowerCase().includes(search);
  
    const matchesGender = !genderFilter || customer.gender.toString() === genderFilter;
    const matchesKyc = !kycFilter || (kycFilter === 'complete' ? customer.isVideoKycDone : !customer.isVideoKycDone);
  
    return (
      (matchesFullName || matchesPan || matchesPhoneNumber || matchesAadharNumber) &&
      matchesGender &&
      matchesKyc
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCustomers = filteredCustomers.slice(indexOfFirstItem, indexOfLastItem);

  const pageCount = Math.ceil((filteredCustomers.length || 0) / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleGenderFilter = event => {
    setGenderFilter(event.target.value);
    setCurrentPage(1);
  };

  const handleKycFilter = event => {
    setKycFilter(event.target.value);
    setCurrentPage(1); 
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      tableRef.current.focus();
    }
  };

  const renderImage = (base64String) => {
    if (!base64String) return null;
    return `data:image/jpeg;base64,${base64String}`;
  };

  return (
    <div className="customer-container" onKeyDown={handleKeyDown} tabIndex={0}>
      <div className="customer-holder">
        <h2>Customer Data</h2>
        <div className="customer-filter">
          <input
            type="text"
            id="searchInput"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <select
            id="genderFilter"
            value={genderFilter}
            onChange={handleGenderFilter}
            className="filter-select"
          >
            <option value="">All Genders</option>
            <option value="1">Male</option>
            <option value="2">Female</option>
            <option value="3">Other</option>
          </select>
          <select
            id="kycFilter"
            value={kycFilter}
            onChange={handleKycFilter}
            className="filter-select"
          >
            <option value="">All KYC Status</option>
            <option value="complete">Complete KYC</option>
            <option value="incomplete">Incomplete KYC</option>
          </select>
        </div>
        <table className="customer-table" ref={tableRef} tabIndex={0}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>PAN Number</th>
              <th>Aadhar Number</th>
              <th>Gender</th>
              <th>KYC Status</th>
              <th>KYC Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentCustomers.map(customer => (
              <tr key={customer.customerId}>
                <td>{`${customer.firstName} ${customer.lastName}`}</td>
                <td>{customer.phoneNumber}</td>
                <td>{customer.panNumber}</td>
                <td>{customer.aadharNumber}</td>
                <td>{customer.gender === 1 ? 'Male' : customer.gender === 2 ? 'Female' : 'Other'}</td>
                <td className={customer.isVideoKycDone ? 'kyc-true' : 'kyc-false'}>
                  {customer.isVideoKycDone ? 'Complete' : 'Incomplete'}
                </td>
                <td>{customer.vkycRequestDateTime ? '' : customer.vkycRequestDateTime}</td>
                <td>
                <Link to={`/virtual-kyc`} state={{ imageUrl: renderImage(customer.pancardimage) }}>
                  Verify
                  </Link>
                </td>
                {/* <td>
                {renderImage(customer.pancardimage) ? (
                    <img src={renderImage(customer.pancardimage)} alt='pan phto' />
                  ) : (
                    <span>No Image</span>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="customer-pagination">
          {Array.from({ length: pageCount }).map((_, index) => (
            <button key={index + 1} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          ))}
        </div>
        
      </div>
    </div>
  );
}

export default CustomerData;
