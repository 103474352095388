import React, { useContext, useEffect } from 'react'
import './videoplayer.css'
import { SocketContext } from '../../../context/SocketContext';
import { FaMicrophoneSlash } from 'react-icons/fa6';

const VideoPlayer = () => {
  const { name, callAccepted, myVideo, userVideo, callEnded, stream, call, isRemoteMuted  } = useContext(SocketContext);

  if (callEnded) {
    if (myVideo.current.srcObject) {
      const tracks = myVideo.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }


    if (userVideo.current.srcObject) {
      const tracks = userVideo.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  }
  useEffect(() => {
    if (callEnded) {
      if (myVideo.current && myVideo.current.srcObject) {
        const tracks = myVideo.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }

      if (userVideo.current && userVideo.current.srcObject) {
        const tracks = userVideo.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  }, [callEnded]);

  return (
    <div className='video-container'>
      {stream ? (
          <div className='video-paper'>
            <div className='video-grid'>
            <h5 variant="h5">{name || 'Name'}</h5>
            <video playsInline muted ref={myVideo} autoPlay className='video-stream' />
          </div>
          </div>
        ) : (
          <div className='loading-indicator'>Loading...</div>
        )}
      {callAccepted && !callEnded && (
        <div className='video-paper'>
          <div className='video-grid'>
            <h5 variant="h5">{call.name || 'Name'}</h5>
            <video playsInline ref={userVideo} autoPlay className='video-stream' />
            {isRemoteMuted && ( // Display indicator if remote user is muted
              <div className="remote-indicator muted">
                <FaMicrophoneSlash />
                <span>Remote User Muted</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};


export default VideoPlayer