import React from 'react';
import Modal from 'react-modal';
import './photomodal.css'

const PhotoModal = ({ isOpen, onClose, photoUrl, panCardImage }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
    
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
          width: '80%',
          maxWidth: '800px',
          margin: 'auto',
          borderRadius: '8px',
          border: 'none',
          padding: '20px',
          backgroundColor: 'white',
        },
      }}
    >
      <h2>Compare the Photos</h2>
      <div className='modal-holder'>
      {photoUrl && (<>
                      <div className='compare-container'>
                      <img src={photoUrl} alt="Captured"  />
                      <img src={panCardImage} alt="Captured"  />
                      </div>
                      </>)}
      <div className='action-btns'>
      <button onClick={onClose} style={{ marginTop: '20px', background: '#039487' }}>
        verify
      </button>
      <button onClick={onClose} style={{ marginTop: '20px', background: '#cf142b', color: 'white' }}>
        Close
      </button>
      </div>

      </div>
    </Modal>
  );
};

export default PhotoModal;
