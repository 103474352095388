import React, { useEffect, useRef, useState } from 'react';
import './otpmodal.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import API_URL from '../../utility/api';
import { handleApiPostRequest } from '../../utility/handleApiRequest';
import { toast } from 'react-toastify';

function OtpModal({ otpData, contact, email }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [attemptsLeft, setAttemptsLeft] = useState(3);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRefs = useRef([]);
  const [seconds, setSeconds] = useState(60);
  const [showOtpSendOnText, setShowOtpSendOnText] = useState('');

  const handleChange = (index, event) => {
    const otpCopy = [...otp];
    otpCopy[index] = event.target.value.slice(0, 1); 
    setOtp(otpCopy);

    if (event.target.value !== '' && index < 6 - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = () => {
    navigate('/home');
  };
  
  const handleVerifyOTP = async () => {
    try {
      const url = API_URL + '/OtpVerify/VerifyOtp';
      const postData = {
        otpNumber: otp.join(''),
        securityStamp: otpData.securityStamp
      };
      const data = await handleApiPostRequest(url, postData);
      toast.success("Valid OTP");
      navigate('/home');
      // Process data as needed
    } catch (error) {
      toast.error("Invalid OTP");
      console.error('Error verifying OTP:', error);
      setErrorMessage(t('wrongOtpEntered'));
      setOtp(new Array(6).fill(''));

      setAttemptsLeft(prevAttempts => prevAttempts - 1);
      if (attemptsLeft === 1) {

        navigate('/attempt-limit-reached');
      }
    }
  };

  useEffect(() => {
    let timerId = null;

    if (resendDisabled && !timerId) { 
      timerId = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            setResendDisabled(false);
            clearInterval(timerId);
            return 60;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timerId); 
  }, [resendDisabled]);

  const handleResendOTP = () => {
    if (!resendDisabled) {
      setResendDisabled(true);
      setSeconds(60);
    }
  };

  const isOtpFilled = otp.every(value => value !== '');

  return (
    <div className='otp-container'>
      <div className='otp-holder'>
        <h1>{t('verifyEmailIDWithOTP')}</h1>
        <p className='entered-email'>{ email }</p>
        <p className='entered-email'>{ contact }</p>
        {/* <p className='entered-email'>{ otpData.otpNumber }</p> */}
        
        <div className='otp-field'>
          {otp.map((value, index) => (
            <input
              key={index}
              type="number"
              maxLength={1}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              ref={(input) => (inputRefs.current[index] = input)}
              className={value ? 'filled' : ''}
            />
          ))}
        </div>
        {errorMessage && <p className='otp-tries'>{errorMessage} {attemptsLeft}</p>}
        {!errorMessage && <p className='otp-tries'>{t('oTPisRequiredAttemptsLeft')} {attemptsLeft}</p>}
        <span className='otp-resend' onClick={handleResendOTP} disabled={resendDisabled || seconds !== 60}>
          <p
            className={`resend-text ${resendDisabled ? 'resend-text-disabled' : 'resend-text-enabled'}`}
          >
            {t('resendOTP')}
          </p>
          {resendDisabled ? seconds !== 0 ? <span className='resend-timer'>{seconds}</span> : null : null}
        </span>
        <button className='btn' onClick={handleVerifyOTP} disabled={!isOtpFilled} style={{ opacity: isOtpFilled ? 1 : 0.5, cursor: isOtpFilled ? 'pointer' : 'not-allowed' }}>
          {t('proceed')}
        </button>
      </div>
    </div>
  );
}

export default OtpModal;
