// Dropdown.js

import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { IoCheckmark } from 'react-icons/io5';
import './dropdown.css';

const Dropdown = ({ title, options, selectedOption, onSelect }) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleOptionClick = (option) => {
    onSelect(option); // Update selected option
    setShowOptions(false); // Close dropdown after selection
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="dropdown-field">
      <div className="custom-dropdown">
        <div className="dropdown-header" onClick={toggleOptions}>
          <input
            type="text"
            value={selectedOption.label} // Use selectedOption.label for display
            placeholder={title}
            readOnly
          />
          <FaChevronDown />
        </div>
        {showOptions && (
          <div className="dropdown-options">
            {options.map((option) => (
              <p key={option.value} onClick={() => handleOptionClick(option)}>
                <span>{option.label}</span>
                {selectedOption.value === option.value && (
                  <span><IoCheckmark size={16} /></span>
                )}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
