import React, { useState, useEffect } from 'react';
import './mitcconsent.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function MitcConsent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const [consentCheckboxes, setConsentCheckboxes] = useState([
    {
      id: 1,
      text: 'I hereby agree and understand that the Bank records will get updated with my personal details and demographic details (Name, Address, Mobile No, DOB, email id etc.) with the latest information provided here.',
      checked: false
    },
    {
      id: 2,
      text: 'I acknowledge that I am sharing my Aadhaar details voluntarily for the purpose of availing banking services and the Bank can use my Aadhaar details as per applicable laws and regulatory guidelines for the purpose of e-KYC, authentication, and verification.',
      checked: false
    },
    {
      id: 3,
      text: 'I consent to receive communication via email and SMS from the Bank regarding updates, offers, and other relevant information.',
      checked: false
    },
    {
      id: 4,
      text: 'I confirm that I have read and understood the terms and conditions related to the 811 bank account and agree to abide by them.',
      checked: false
    },
  ]);

  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = consentCheckboxes.map(checkbox =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
    setConsentCheckboxes(updatedCheckboxes);
  };

  useEffect(() => {
    const allChecked = consentCheckboxes.every(checkbox => checkbox.checked);
    setAllChecked(allChecked);
  }, [consentCheckboxes]);

  const handleSelectAll = () => {
    const allChecked = !selectAll;
    const updatedCheckboxes = consentCheckboxes.map(checkbox => ({
      ...checkbox,
      checked: allChecked
    }));
    setConsentCheckboxes(updatedCheckboxes);
    setSelectAll(allChecked);
  };

  const handleProceed = () =>{
     //   navigate('/time-slot')
        navigate('/success')
  }

  const consentText = `I also understand that the Bank may share my transaction details with regulatory or enforcement authorities whenever such information is requested as per legal requirements or in compliance with regulatory obligations. I acknowledge that this authorization extends to the sharing of my transaction details for the purpose of ensuring compliance with applicable laws and regulations, including but not limited to anti-money laundering (AML) and combating the financing of terrorism (CFT) laws.

  Furthermore, I agree to promptly inform the Bank of any changes to the information provided during the Video KYC journey, and I understand that failure to do so may lead to the suspension or closure of my 811 bank account.`;

  const truncatedText = expanded ? consentText : consentText.slice(0, 200) + '...';

  return (
    <div className='fc-container'>
      <div className='fc-holder'>
        <h3>{t('mITCConsent')}</h3>
        <div className='consent-text'>
          <p className='fc-para'>{expanded ? consentText : truncatedText}</p>
          {!expanded && (
            <p onClick={toggleExpand} className="read-more-txt">
              {t('readMore')}
            </p>
          )}
        </div>
        <span className='fc-select' onClick={handleSelectAll}>
          {selectAll ? t('deselectAll') : t('selectAll')}
        </span>
        {consentCheckboxes.map(checkbox => (
          <div key={checkbox.id} className='checkbox-field'>
            <input
              type='checkbox'
              checked={checkbox.checked}
              onChange={() => handleCheckboxChange(checkbox.id)}
            />
            <p className='consent-para'>{checkbox.text}</p>
          </div>
        ))}
        <button className='fc-proceed' onClick={handleProceed} disabled={!allChecked}>{t('Agree')}</button>
      </div>
    </div>
  );
}

export default MitcConsent;
