import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import './cameracapture.css';
import flipCamera from '../../assets/images/camera-flip-svgrepo-com.svg';
import clickPhoto from '../../assets/images/camera-svgrepo-com.svg';
import retakePhoto from '../../assets/images/camera_retake_outline_icon_139052.svg';
import savePhoto from '../../assets/images/check-svgrepo-com.svg';
import cancel from '../../assets/images/cancel-svgrepo-com.svg';

function CameraCapture({ isModalOpen, closeModal, onCapture }) {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCaptured, setIsCaptured] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  const toggleCamera = () => {
    setIsFrontCamera(prevIsFront => !prevIsFront); 
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    setIsCaptured(true);
  };

  const retakeImage = () => {
    setCapturedImage(null);
    setIsCaptured(false);
  };

  const saveImage = () => {
    if (capturedImage) {
      onCapture(capturedImage);
      setIsCaptured(false);
      setCapturedImage(null);
      closeModal();
    }
  };

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: isFrontCamera ? 'user' : { exact: 'environment' } }
        });
        webcamRef.current.srcObject = stream;
      } catch (error) {
        console.error('Error accessing camera:', error);
      }
    };

    if (isModalOpen) {
      startCamera();
    }

    return () => {
      if (webcamRef.current) {
        webcamRef.current.srcObject?.getTracks().forEach(track => track.stop());
      }
    };
  }, [isModalOpen, isFrontCamera]);

  return (
    <>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="camera-container">
              {isCaptured ? (
                <img src={capturedImage} alt="Captured" className="captured-image" />
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="camera-view"
                  videoConstraints={{ facingMode: isFrontCamera ? 'user' : { exact: 'environment' } }}
                />
              )}
            </div>
            <div className="camera-controls">
              <button className='action-btn' onClick={toggleCamera}>
                <img src={flipCamera} alt="Flip Camera" />
              </button>
              {!isCaptured && (
                <button className='action-btn' onClick={captureImage}>
                  <img src={clickPhoto} alt="Capture img" />
                </button>
              )}
              {isCaptured && (
                <>
                  <button className='action-btn' onClick={retakeImage}>
                    <img src={retakePhoto} alt="Retake img" />
                  </button>
                  <button className='action-btn' onClick={saveImage}>
                    <img src={savePhoto} alt="Save img" />
                  </button>
                </>
              )}
              <button className='action-btn' onClick={closeModal}>
                <img src={cancel} alt="Cancel" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CameraCapture;
