import React, { createContext, useContext, useState } from 'react';


const RegisterDataContext = createContext();


export const useRegisterData = () => useContext(RegisterDataContext);


export const RegisterDataProvider = ({ children }) => {

  const [registerData, setRegisterData] = useState({
  
    contact: '',
    email: '',
    pincode: '',

  });


  return (
    <RegisterDataContext.Provider value={{ registerData, setRegisterData }}>
      {children}
    </RegisterDataContext.Provider>
  );
};
