import React, { useState, useEffect } from 'react';
import './setaddress.css';
import { FiPlus, FiSave, FiX } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useData } from '../../context/AdharDataContext';
import axios from 'axios';
import API_URL from '../../utility/api';

function SetAddress() {
  const { adharData, userData } = useData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [streetNo, setStreetNo] = useState('');
  const [landmark, setLandmark] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);

console.log(adharData)
  const handleAddAddress = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    clearFormFields();
  };

  const handleSaveAddress = () => {
    if (name && streetNo && district && state && pincode) {
      const newAddress = {
        name,
        streetNo,
        landmark,
        district,
        state,
        pincode
      };
      setAddresses([...addresses, newAddress]);
      clearFormFields();
      setShowForm(false);
    } else {
      alert('Please fill in all fields');
    }
  };

  const handleProceed = () => {
    navigate('/add-nominee');
    if (selectedAddressIndex !== null) {
      let selectedAddress = null;
  
      if (selectedAddressIndex === 0 && adharData && adharData.data) {
        selectedAddress = {
          name: adharData.data.full_name,
          streetNo: `${adharData.data.address.loc}, ${adharData.data.address.po}`,
          landmark: adharData.data.address.landmark,
          apartment: adharData.data.address.house,
          district: adharData.data.address.dist,
          state: adharData.data.address.state,
          city: adharData.data.address.dist,
          pincode: adharData.data.address.po
        };
      } else if (selectedAddressIndex > 0 && addresses.length > 0) {
        selectedAddress = addresses[selectedAddressIndex - 1];
      }
  
      if (selectedAddress) {
        const payload = {
          customerId: userData.customerId || '',
          addressList: [
            {
              addressId: userData.customerId || '', 
              entityGuid: userData.customerId || '', 
              addressName: selectedAddress.name || '', 
              blockNo: selectedAddress.streetNo || '',
              apartment: selectedAddress.apartment || '', 
              street: selectedAddress.streetNo || '',
              landmark: selectedAddress.landmark || '',
              district: selectedAddress.district || '',
              city: selectedAddress.city || '',
              state: selectedAddress.state || '',
              zipCode: selectedAddress.pincode || '',
              addressType: 1,
              active: true
            }
          ]
        };
  
        console.log('Payload:', payload);
  
        axios.post(API_URL + '/Customer/UpdateCustomerAddress', payload)
          .then(response => {
            console.log('Address sent to backend successfully:', response.data);
            navigate('/add-nominee');
          })
          .catch(error => {
            console.error('Error sending address to backend:', error);
          });
      } else {
        console.error('Invalid selected address:', selectedAddress);
      }
    } else {
      alert('Please select an address');
    }
  };
  
  

  const clearFormFields = () => {
    setName('');
    setStreetNo('');
    setLandmark('');
    setDistrict('');
    setState('');
    setPincode('');
  };

  const handleSelectAddress = (index) => {
    setSelectedAddressIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='address-container'>
      <div className='address-holder'>
        <h3>{t('setCommunicationAddress')}</h3>
        <p>{t('lettersDebitCardsOtherDocuments')} <br/> {t('willBeSentToThisAddress')}</p>
        {adharData && (
          <div
            className={`address-card ${selectedAddressIndex === 0 ? 'selected' : ''}`}
            onClick={() => handleSelectAddress(0)}
          >
            <div className='left'>
              <input
                type='radio'
                id='address-0'
                checked={selectedAddressIndex === 0}
                onChange={() => handleSelectAddress(0)}
              />
            </div>
            <div className='right'>
              <p><b>{adharData.data.full_name}</b></p>
              <p>
                {adharData.data.address.loc}, {adharData.data.address.po}, {adharData.data.address.dist}, {adharData.data.address.state} - {adharData.data.address.zip}
              </p>
            </div>
          </div>
        )}

        <div className="address-cards">
          {addresses.map((address, index) => (
            <div
              key={index}
              className={`address-card ${selectedAddressIndex === index + 1 ? 'selected' : ''}`}
              onClick={() => handleSelectAddress(index + 1)}
            >
              <div className='left'>
                <input
                  type='radio'
                  id={`address-${index}`}
                  checked={selectedAddressIndex === index + 1}
                  onChange={() => handleSelectAddress(index + 1)}
                />
              </div>
              <div className='right'>
                <p><b>{address.name}</b></p>
                <p>{address.streetNo}, {address.landmark}, {address.district}, {address.state} - {address.pincode}</p>
              </div>
            </div>
          ))}
        </div>

        {showForm && (
          <div className='address-form'>
            <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder="Street No." value={streetNo} onChange={(e) => setStreetNo(e.target.value)} />
            <input type="text" placeholder="Landmark." value={landmark} onChange={(e) => setLandmark(e.target.value)} />
            <input type="text" placeholder="District" value={district} onChange={(e) => setDistrict(e.target.value)} />
            <input type="text" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
            <input type="text" placeholder="Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
            <div className="form-buttons">
              <button type="button" className='btn-cancel' onClick={handleCancel}><FiX /> {t('cancel')}</button>
              <button type="button" className='btn-save' onClick={handleSaveAddress}><FiSave /> {t('save')}</button>
            </div>
          </div>
        )}
        
        {!showForm && (
          <div className='add-address' onClick={handleAddAddress}><FiPlus /> {t('addNewAddress')}</div>
        )}

        <button className='address-proceed' onClick={handleProceed} disabled={selectedAddressIndex === null}>
          {t('proceedWithAddress')}
        </button>
      </div>
    </div>
  );
}

export default SetAddress;
