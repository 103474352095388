import React, { createContext, useContext, useState } from 'react';

const AdharDataContext = createContext();

export const AdharDataProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [adharData, setAdharData] = useState(null);

  return (
    <AdharDataContext.Provider value={{ userData, setUserData, adharData, setAdharData }}>
      {children}
    </AdharDataContext.Provider>
  );
};

export const useData = () => useContext(AdharDataContext);
