import React, { useContext } from 'react'
import './notification.css'
import { SocketContext } from '../../../context/SocketContext';

const Notification = () => {
  const { answerCall, call, callAccepted } = useContext(SocketContext);

  return (
    <>
    {call.isReceivingCall && !callAccepted && (
      <div style={{ display: 'flex', justifyContent: 'space-around' }} className='notification-container'>
        <h3>{call.name} is calling:</h3>
        <button  onClick={answerCall}>
          Answer
        </button>
      </div>
    )}
  </>
  )
}

export default Notification