import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from './i18n/en/translation.json'
import hindi from './i18n/hindi/translation.json'
import marathi from './i18n/marathi/translation.json'


i18next.use(initReactI18next).init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
resources: {
    en:{
        translation: en,
    },
    hindi:{
        translation: hindi,
    },
    marathi:{
        translation: marathi,
    }

}
})

export default i18next;